import Container from 'Components/Library/BaseComponents/Container';
import { useDispatch, useSelector } from 'react-redux';
import { objectivesActions, objectivesSelectors } from 'state/ducks/objectives';
import { useTranslation } from 'react-i18next';
import Button from 'Components/Library/BaseComponents/Button';
import Form from 'Components/Library/Forms';
import { gameplansActions } from 'state/ducks/gameplans';
import { FormOptions } from './index';
import FormButton from 'Components/Library/Forms/Elements/FormButton';
import { useState } from 'react';
import { nanoid } from 'nanoid';
import LinearIndicator from 'Components/Library/Forms/Elements/LinearIndicator';
import { WidgetSelector } from './WidgetSelector';
import { Box, Divider, Typography } from '@mui/material';
import { ObjectivesOptions, OkrConfig } from './ObjectivesOptions';
import OverwriteWarning from './OverwriteWarning';

interface CopyFormProps {
  teamId: string;
  targetPeriod: string;
  targetGameplanId: string;
  sourceGameplan: any;
  options: FormOptions;
  onClose(): void;
  onSuccess(): void;
  okrs: any;
  initialValues: { widgets_to_copy: { [key: string]: boolean } };
  variant?: 'continue-flow' | 'copy';
}

function CopyForm(props: CopyFormProps) {
  const {
    targetPeriod,
    okrs,
    initialValues,
    targetGameplanId,
    sourceGameplan,
    teamId,
    options,
    onSuccess,
    onClose,
    variant,
  } = props;
  const [copyOkrConfig, setCopyOkrConfig] = useState<OkrConfig>({ opt: 'create_widget' });
  const [okrCopyRequests, setOkrCopyRequests] = useState([]);

  const { t } = useTranslation();

  const ltperiod = useSelector((state: any) =>
    objectivesSelectors.selectLtPeriodForStPeriod(state.main.objectives, targetPeriod),
  );

  const actionlog = useSelector((state: any) => state.main.objectives.actionlog);

  const dispatch = useDispatch();
  const sourceLtperiod = sourceGameplan.ltperiod;

  if (okrCopyRequests.length > 0) {
    let notDoneCount = 0;
    for (const requestID of okrCopyRequests) {
      if (!(requestID in actionlog)) {
        notDoneCount++;
      }
    }
    if (notDoneCount === 0) {
      // force refresh of data
      dispatch(
        objectivesActions.getTeamObjectives({
          teamId: teamId,
          stperiod: targetPeriod,
          force: true,
        }),
      );
      onSuccess();
    }
  }

  const schema = {
    type: 'object',
    properties: {
      widgets_to_copy: {
        type: 'object',
      },
    },
  };

  const onCopyGpSuccess = () => {
    if (copyOkrConfig) {
      // Gameplan copying done, now copy OKRs
      const includeLongTermOkrs = sourceLtperiod !== ltperiod;
      const requestIDs = [];
      if (['copy_objectives', 'copy_full_okrs'].includes(copyOkrConfig.opt)) {
        for (const objectiveData of okrs[0].data) {
          const isShortTerm = Boolean(objectiveData.data.stperiod);

          if (isShortTerm || includeLongTermOkrs) {
            const requestID = nanoid(10);
            requestIDs.push(requestID);
            const payload: any = {
              requestID,
              objectiveID: objectiveData.data.objectiveID,
              includeKrs: copyOkrConfig.opt === 'copy_full_okrs',
              ltperiod: ltperiod,
            };
            if (isShortTerm) {
              payload.stperiod = targetPeriod;
            }
            dispatch(objectivesActions.copyObjectiveToPeriod(payload));
            setOkrCopyRequests(requestIDs);
          }
        }
      }
    } else {
      onSuccess();
    }
  };

  const createReduxAction = params => {
    const { ...rest } = params;
    const actionCreator = gameplansActions.copyGameplan;
    rest.widgets_to_copy = Object.keys(rest.widgets_to_copy).filter(
      key => rest.widgets_to_copy[key] === true,
    );
    return actionCreator(rest);
  };

  return (
    <Form
      name="copy-gameplan-form"
      schema={schema}
      initialValues={initialValues}
      preventSaveIncomplete
      allowRefreshData
      submitActionCreator={createReduxAction}
      additionalProperties={{
        source_gameplan_id: sourceGameplan.id,
        target_gameplan_id: targetGameplanId,
        ltperiod: ltperiod,
        stperiod: targetPeriod ? targetPeriod.split('-')[1] : '',
      }}
      stateSlice="main.gameplans"
      onSubmitSuccess={result => {
        onCopyGpSuccess();
      }}
      style={{ overflowY: 'hidden', minHeight: '0px', display: 'flex', flexDirection: 'column' }}
    >
      <LinearIndicator submittingOverride={okrCopyRequests.length > 0} positioning={'absolute'} />
      <Box display="flex" flexDirection="column" sx={{ overflowY: 'hidden' }}>
        <Box
          sx={{
            flexShrink: 20,
            flexGrow: 0,
            minHeight: '0px',
            overflowY: 'auto',
          }}
          className="scrollable"
        >
          <Container spacing={2} m={2} alignItems="stretch">
            {options.core.length > 0 && (
              <Container spacing={1} alignItems="stretch">
                <Typography variant="subtitle2">
                  {t('gameplans.copyMenu.coreWidgetsTitle')}
                </Typography>
                {options.core.map(widgetData => (
                  <WidgetSelector widgetData={widgetData} />
                ))}
              </Container>
            )}
            <ObjectivesOptions
              okrWidgets={options.okr}
              otherAvailableOptions={options.otherAvailableOptions}
              setOkrOptions={setCopyOkrConfig}
              okrOptions={copyOkrConfig}
            />

            {options.contentCards.length > 0 && (
              <Container spacing={1} alignItems="stretch">
                <Typography sx={{ pt: 2 }} variant="subtitle2">
                  {t('gameplans.copyMenu.customCardsTitle')}
                </Typography>
                {options.contentCards.map(widgetData => (
                  <WidgetSelector widgetData={widgetData} />
                ))}
              </Container>
            )}

            {options.commitmentsAndInterlocks.length > 0 && (
              <Container spacing={1} alignItems="stretch">
                <Typography sx={{ pt: 2 }} variant="subtitle2">
                  {t('gameplans.copyMenu.intcomTitle')}
                </Typography>
                <Box sx={{ pt: 1, pb: 1 }}>
                  <Box
                    sx={{
                      borderRadius: 1,
                      border: '2px solid',
                      borderColor: 'action.border',
                      p: 2,
                    }}
                  >
                    <Typography variant="subtitle2" color="text.secondary">
                      {t('gameplans.copyMenu.intcomSubtitle')}
                    </Typography>
                  </Box>
                </Box>
                {options.commitmentsAndInterlocks.map(widgetData => (
                  <WidgetSelector widgetData={widgetData} />
                ))}
              </Container>
            )}
          </Container>
        </Box>
        <Box
          sx={{
            flexShrink: 0,
            flexGrow: 0,
            minHeight: '0px',
          }}
        >
          <Divider />
          <Container m={3}>
            <OverwriteWarning
              teamId={teamId}
              sourceGameplan={sourceGameplan}
              targetGameplanId={targetGameplanId}
            />
            <Container sx={{ width: '100%' }} direction="row" justifyContent="flex-end">
              <Button
                id="copy-gameplan-form-cancel"
                variant="text"
                size="large"
                onClick={() => onClose()}
              >
                {t('general.cancel')}
              </Button>
              <FormButton
                render={buttonProps => (
                  <Button
                    id="copy-gameplan-form-submit"
                    variant="contained"
                    size="large"
                    color="secondary"
                    {...buttonProps}
                  >
                    {variant === 'continue-flow'
                      ? t('gameplans.copyMenu.continueCTA')
                      : t('general.copy')}
                  </Button>
                )}
              />
            </Container>
          </Container>
        </Box>
      </Box>
    </Form>
  );
}

export default CopyForm;
