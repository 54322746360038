import { useSelector } from 'react-redux';
import GamePlanDashboard from './GamePlanDashboard';
import { objectivesSelectors } from 'state/ducks/objectives';
import useFeatureFlag from 'hooks/useFeatureFlag';
import PeriodNavigator from 'Components/Features/Objectives/PeriodNavigator';
import Container from 'Components/Library/BaseComponents/Container';
import useGameplanData from 'hooks/useGameplanData';
import { isPast } from 'Components/Features/Objectives/PeriodSelector/SearchOverlay';
import { useMemo } from 'react';
import EmptyStateWithActions from './EmptyStateWithActions';
import { Chip, Skeleton } from '@mui/material';
import { t } from 'i18next';
import { isEmpty } from 'lodash';

function GamePlanDashboardContainer(props: any) {
  const { teamId, handlePeriodChange, selectedPeriod } = props;

  const periodConfig = useSelector((state: any) =>
    objectivesSelectors.selectPeriodConfig(state.main.objectives),
  );

  const currentStPeriod = periodConfig?.data?.activeperiod?.stperiod;

  const nextShortTermPeriod = useSelector((state: any) =>
    objectivesSelectors.selectNextShortTermPeriod(state.main.objectives, selectedPeriod),
  );
  const prevShortTermPeriod = useSelector((state: any) =>
    objectivesSelectors.selectPrevShortTermPeriod(state.main.objectives, selectedPeriod),
  );

  const usePeriodicGameplans = useFeatureFlag('PERIODIC-GAMEPLANS');

  const gameplans = useGameplanData(teamId);

  const gameplansPerPeriod = useMemo(() => {
    const v = {};
    if (usePeriodicGameplans && gameplans.ok) {
      for (const gameplan of gameplans.data) {
        if (gameplan.stperiod) {
          v[`${gameplan.ltperiod}-${gameplan.stperiod}`] = gameplan.id;
        }
      }
    }
    return v;
  }, [gameplans, usePeriodicGameplans]);

  if (!gameplans.ok || !periodConfig.ok) {
    return <Skeleton variant="rounded" height={400} />;
  }

  const periodFilterFunc = p => {
    // Filter out long term periods
    if (!p.stPeriodId) {
      return false;
    }
    return true;
  };

  let gameplanId;
  let gameplan;
  if (gameplans?.data) {
    if (!usePeriodicGameplans) {
      gameplan = gameplans.data[0];
    } else {
      gameplanId = gameplansPerPeriod[selectedPeriod];
      if (gameplanId) {
        gameplan = gameplans.data.find(gp => gp.id === gameplanId);
      }
    }
  }

  const isPastPeriod = isPast(periodConfig.data.stperiodconfigs[selectedPeriod]?.periodStart);

  let chipLabel = t('gameplans.pastPeriodChip');
  let chipColor;
  let chipVariant: 'filled' | 'outlined' = 'filled';
  if (currentStPeriod === selectedPeriod) {
    chipVariant = 'outlined';
    chipColor = 'secondary';
    chipLabel = t('gameplans.currentPeriodChip');
  } else if (!isPastPeriod) {
    chipLabel = t('gameplans.futurePeriodChip');
  }

  const isEmptyGameplan = gameplan && isEmpty(gameplan.widget_visibility);

  return (
    <>
      {usePeriodicGameplans && (
        <Container direction="row" m={0} mb={2}>
          <PeriodNavigator
            handlePeriodChange={handlePeriodChange}
            filterFunc={periodFilterFunc}
            selectedPeriod={selectedPeriod}
            nextPeriod={nextShortTermPeriod}
            prevPeriod={prevShortTermPeriod}
            floating
          />
          <Chip label={chipLabel} color={chipColor} variant={chipVariant} />
        </Container>
      )}
      {gameplan && !isEmptyGameplan ? (
        <GamePlanDashboard {...props} gameplan={gameplan} />
      ) : (
        <EmptyStateWithActions
          gameplansPerPeriod={gameplansPerPeriod}
          isPastPeriod={isPastPeriod}
          selectedPeriod={selectedPeriod}
          periodConfig={periodConfig}
          teamId={teamId}
          handlePeriodChange={handlePeriodChange}
          isEmptyGameplan={isEmptyGameplan}
        />
      )}
    </>
  );
}

export default GamePlanDashboardContainer;
