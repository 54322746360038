import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import Menu from '@mui/material/Menu';
import MuiMenuItem from '@mui/material/MenuItem';
import ArrowRight from '@mui/icons-material/ChevronRight';
import ArrowDown from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import DataObjectChip from 'Components/Library/DataObjectChip';

const styles = theme => ({
  toggleMenu: {
    verticalAlign: 'middle',
  },
  menu: {
    ...theme.shape,
    boxShadow: theme.shadows[4],
    backgroundColor: '#FFFFFF',
    position: 'relative',
    top: 0,
    left: 0,
  },
  menuWrapper: {
    position: 'absolute',
    ...theme.shape,
    zIndex: 50,
    top: 0,
    left: 0,
  },
  menuContainer: {
    position: 'relative',
    width: 0,
    height: 1,
  },
  dark: {
    color: theme.palette.text.primary,
  },
  normalButton: {
    padding: 8,
    paddingLeft: theme.spacing(2),
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'row',
    whiteSpace: 'nowrap',
    height: 32,
    padding: 8,
    paddingRight: theme.spacing(2),
    paddingLeft: 0,
    alignItems: 'center',
    cursor: 'pointer',
  },
  disabled: {
    color: 'rgba(0,0,0,0.6)',
    cursor: 'auto',
  },
});

function Spacer({ depth }) {
  return <div style={{ width: depth * 8, height: '100%' }} />;
}

Spacer.propTypes = {
  depth: PropTypes.number,
};

function DivMenuItem({ classes, disabled, children, onClick }) {
  return (
    <div onClick={onClick} className={clsx([classes.menuItem, disabled ? classes.disabled : null])}>
      {children}
    </div>
  );
}

DivMenuItem.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.exact({
    button: PropTypes.string,
    toggleMenu: PropTypes.string,
    menu: PropTypes.string,
    menuWrapper: PropTypes.string,
    menuContainer: PropTypes.string,
    dark: PropTypes.string,
    normalButton: PropTypes.string,
    menuItem: PropTypes.string,
    disabled: PropTypes.string,
  }),
  disabled: PropTypes.bool,
};

function recurse(obj, searchString) {
  let namerecurse = null;
  if (obj.id === searchString) {
    namerecurse = obj.name;
  } else {
    for (const child of obj.children) {
      namerecurse = recurse(child, searchString);
      if (!!namerecurse) {
        break;
      }
    }
  }
  return namerecurse;
}

const StyledMenuItem = withStyles(styles)(DivMenuItem);

class TeamDropDown extends Component {
  state = {
    openTeams: [],
    menuOpen: null,
  };

  getSelectedTeamName = teamId => recurse(this.props.orgTree, teamId) || '! Not found !';

  onTeamExpanded = (e, teamId) => {
    e.stopPropagation();
    this.setState({ openTeams: [...this.state.openTeams, teamId] });
  };

  onTeamCollapsed = (e, teamId) => {
    e.stopPropagation();
    this.setState({ openTeams: this.state.openTeams.filter(t => t !== teamId) });
  };

  onTeamSelected = (teamId, teamName) => {
    this.props.onTeamSelected({ teamId, teamName });
    this.setState({ menuOpen: null });
  };

  openMenu = event => {
    if (!this.props.disabled) {
      this.setState({ menuOpen: event.currentTarget });
    }
  };

  closeMenu = () => {
    this.setState({ menuOpen: null });
  };

  renderTeam = (team, depth) => {
    const { disableCondition, classes, variant = 'normal', t } = this.props;

    const MenuItem = variant === 'normal' ? MuiMenuItem : StyledMenuItem;

    const className = variant === 'normal' ? null : classes.menuItem;

    if (this.state.openTeams.includes(team.id) || depth === 0) {
      const items = [];

      items.push(
        <MenuItem
          disabled={disableCondition && disableCondition(team)}
          onClick={() => this.onTeamSelected(team.id, team.name)}
          key={`teamselector-${team.id}`}
          id={`teamselector-${team.id}`}
          name="teamselector-menuitem"
          dense
        >
          <Spacer depth={depth} />
          <IconButton
            disabled={disableCondition && disableCondition(team)}
            onClick={e => this.onTeamCollapsed(e, team.id)}
            size="small"
            aria-label={t('general.close')}
          >
            <ArrowDown />
          </IconButton>
          {team.name}
        </MenuItem>,
      );

      team.children.map(tt => items.push(this.renderTeam(tt, depth + 1)));

      return items;
    }
    if (team.children && team.children.length > 0) {
      return (
        <MenuItem
          className={className}
          disabled={disableCondition && disableCondition(team)}
          onClick={() => this.onTeamSelected(team.id, team.name)}
          key={`teamselector-${team.id}`}
          id={`teamselector-${team.id}`}
          name="teamselector-menuitem"
          dense
        >
          <Spacer depth={depth} />
          <IconButton
            disabled={disableCondition && disableCondition(team)}
            onClick={e => this.onTeamExpanded(e, team.id)}
            size="small"
            aria-label={t('general.expand')}
          >
            <ArrowRight />
          </IconButton>
          {team.name}
        </MenuItem>
      );
    }
    return (
      <MenuItem
        className={className}
        disabled={disableCondition && disableCondition(team)}
        onClick={() => this.onTeamSelected(team.id, team.name)}
        key={`teamselector-${team.id}`}
        id={`teamselector-${team.id}`}
        name="teamselector-menuitem"
        dense
      >
        <Spacer depth={depth} />
        {/* empty icon below */}
        <Spacer depth={6} />
        {team.name}
      </MenuItem>
    );
  };

  render() {
    const {
      classes,
      orgTree,
      className,
      selected,
      color = 'white',
      variant = 'normal',
    } = this.props;
    const { menuOpen } = this.state;

    if (!!!orgTree) {
      return null;
    }

    return (
      <div className={className}>
        <div aria-owns={menuOpen ? 'simple-menu' : undefined} aria-haspopup="true">
          <DataObjectChip
            color={color}
            text={!!selected ? this.getSelectedTeamName(selected) : orgTree && orgTree.name}
            icon={null}
            actionable
            onClick={this.openMenu}
            statusComponent={<ArrowDown />}
            actOnDownArrow
            name="team-selector-teamname"
            dataChipType="team"
          />
        </div>
        {variant === 'normal' ? (
          <Menu open={!!menuOpen} anchorEl={menuOpen} onClose={this.closeMenu}>
            {this.renderTeam(orgTree, 0)}
          </Menu>
        ) : (
          <div className={classes.menuContainer}>
            {!!menuOpen && (
              <div className={classes.menuWrapper}>
                <ClickAwayListener onClickAway={this.closeMenu}>
                  <div className={classes.menu}>{this.renderTeam(orgTree, 0)}</div>
                </ClickAwayListener>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

TeamDropDown.propTypes = {
  orgTree: PropTypes.object,
  classes: PropTypes.exact({
    button: PropTypes.string,
    toggleMenu: PropTypes.string,
    menu: PropTypes.string,
    menuWrapper: PropTypes.string,
    menuContainer: PropTypes.string,
    dark: PropTypes.string,
    normalButton: PropTypes.string,
    menuItem: PropTypes.string,
    disabled: PropTypes.string,
  }),
  onTeamSelected: PropTypes.func,
  disabled: PropTypes.bool,
  disableCondition: PropTypes.func,
  variant: PropTypes.string,
  selected: PropTypes.string,
  color: PropTypes.oneOf(['white', 'grey', 'transparent', 'darkgrey']),
  t: PropTypes.func,
};

export default withTranslation()(withStyles(styles)(TeamDropDown));
